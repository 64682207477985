import EventBus from '../../../utils/eventBus'
import stepMixin from '../../../mixins/stepMixin'
import checkTaskMixin from '../../../mixins/checkTaskMixin'
import customerMixin from '../../../mixins/customerMixin'
import FormCheck from '../../form/formCheck/FormCheck.vue'
import FormInput from '../../form/formInput/FormInput.vue'
import { errorMessage } from '../../../constants/alertMessages'
import { pinSupportPhoneNumber } from '../../../constants/general'
import { mapActions, mapGetters } from 'vuex'
import { getBringMissingPartsDate } from '../../../utils/date-utils'

export default {
    name: 'Pin',

    mixins: [stepMixin, checkTaskMixin, customerMixin],

    components: {
        FormCheck,
        FormInput
    },

    data() {
        return {
            pinIsLoading: false,
            wrongPinError: false,
            pinSupportPhoneNumber,
            showAltPinMsg: false,
        }
    },

    computed: {
        ...mapGetters({
            loggedInUser: 'user/loggedInUser',
            correctPin: 'pin/correctPin'
        }),

        field() {
            return this.form.summary || {}
        },

        isConfirmed() {
            const respectedMissingItems = !this.hasMissingItems || (this.hasMissingItems && this.field?.confirmedMissingItemsCustomer)
            return this.task === 'CustomerCheckin'
                ? (this.field?.confirmed && respectedMissingItems)
                : this.field?.confirmed
        },

        sepaChecked() {
            return this.field?.sepa
        },

        sepaHasToBeChecked() {
            if (this.form.vehicle?.contractSepaNotRequired) {
                return false
            }

            if (this.contractPartnerOnSite) {
                return true
            }

            return !this.hasAuthorizedRepresentative
        },

        // Shows the phone number where the PIN will be sent.
        // Decide between the customer and the representative.
        sendPinToNummber(){
            if(!this.hasAuthorizedRepresentative || this.contractPartnerOnSite){
                return this.customer.phone_number
            }
            return this.authorizedRepresentative.phone_number
        },

        emptyPhonenumber(){
            return (this.sendPinToNummber == '');
        },

        signature() {
            return this.form.summary.signature
        },

        confirmedText() {
            return this.task === 'CustomerCheckin'
                ? 'Ich habe das Übergabeprotokoll gelesen und bestätige die Übergabe wie in dieser Übersicht angezeigt.'
                : 'Ich habe das Übernahmeprotokoll gelesen und bestätige die Übernahme wie in dieser Übersicht angezeigt.'
        },

        confirmedMissingItemsText() {
            return 'Hiermit bestätige ich die fehlenden Gegenstände und mir ist bewusst, dass ich laut AGB dazu verpflichtet ' +
                'bin, diese inklusive aktivem Nachweis, innerhalb von 3 Werktagen bis zum "' + this.getBringMissingPartsDate() + '" nachzureichen. ' +
                'Ansonsten werden mir die Minderwerte in Rechnung gestellt.'
        },

        neededFormsChecked() {
            return (
                this.isConfirmed &&
                (
                    (
                        this.task === 'CustomerCheckout' &&
                        (this.sepaChecked || !this.sepaHasToBeChecked)
                    ) || 
                    this.task === 'CustomerCheckin'
                ) &&
                this.signature
            )
        },

        hasMissingItems() {
            return (this.form?.handoverProtocol?.['missingItems']?.length > 0) ||
                (this.field?.handoverProtocol?.['missingItemsText']?.length > 0)
        },
    },

    methods: {
        ...mapActions({
            requestPin: 'pin/requestPin',
            validatePin: 'pin/validatePin'
        }),

        apiError() {
            EventBus.$emit('showDialog', {
                dialog: {
                    name: 'error',
                    type: 'hint',
                    showContact: true,
                    errors: [
                        'Es gab ein Problem bei der Kommunikation mit der Schnittstelle.'
                    ]
                }
            })
        },

        async getPin() {
            this.pinIsLoading = true
            let response

            const payload = {
                token: this.loggedInUser?.token,
                processId:
                    this.form?.vehicle?.id,
                isAuthorized:
                    this.form?.authority &&
                    !this.form.authority.contractPartnerOnSite
            }

            try {
                response = await this.requestPin(payload)
            } catch {
                this.apiError()
            }

            // Handling errors
            if (response?.error) {
                const statusCode = response.error

                if (statusCode === 500) {
                    this.apiError()
                }

                if (statusCode === 403) {
                    // Token has been expired or is invalid, redirect to logout
                    this.$router.push({
                        name: 'Logout',
                        params: { message: errorMessage.SESSION_EXPIRED }
                    })
                }
            }

            this.pinIsLoading = false
        },

        async validatingPin(pin) {
            this.pinIsLoading = true
            let pinIsCorrect = false

            const payload = {
                token: this.loggedInUser?.token,
                processId:
                    this.form?.vehicle?.id,
                pin
            }

            pinIsCorrect = await this.validatePin(payload)

            if (pinIsCorrect && pinIsCorrect.error) {
                this.wrongPinError = true
            } else if (pinIsCorrect && pinIsCorrect === 'ok') {
                this.$store.dispatch('pin/setCorrectPin', true)
            }

            this.pinIsLoading = false
        },

        toggleAltPinMsg () {
            this.showAltPinMsg = !this.showAltPinMsg
        },

        getBringMissingPartsDate() {
            return getBringMissingPartsDate()
        },
    },

    watch: {
        'field.pin': function(value) {
            // Clear error
            this.wrongPinError = false

            if (value && value.length === 4) {
                this.validatingPin(value)
            }
        }
    }
}
